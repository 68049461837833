var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_vm.trust ? _c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center",
    staticStyle: {
      "text-transform": "uppercase"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("trust.desc-hibah")) + " ")]), ['Staff'].includes(_vm.userRole) ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": "organization_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("partner-name")))]), _vm.organizations ? _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedOrganizationId,
      expression: "selectedOrganizationId"
    }],
    staticClass: "form-control",
    attrs: {
      "name": ""
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.selectedOrganizationId = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, _vm.onOrganizationChange]
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.organization")) + " --")]), _vm._l(Object.entries(this.organizations), function (type, index) {
    return _c('optgroup', {
      key: index,
      attrs: {
        "label": type[0]
      }
    }, _vm._l(type[1], function (organization, index2) {
      return _c('option', {
        key: index2,
        domProps: {
          "value": organization.id
        }
      }, [_vm._v(" " + _vm._s(organization.name) + " ")]);
    }), 0);
  })], 2) : _vm._e()]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": "branch_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("org.nav2")))]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedBranchId,
      expression: "selectedBranchId"
    }],
    staticClass: "form-control",
    attrs: {
      "name": ""
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.selectedBranchId = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, function ($event) {
        return _vm.selectBranch($event);
      }]
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.branch")) + " --")]), _vm._l(_vm.branches, function (branch, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": branch.id
      }
    }, [_vm._v(" " + _vm._s(branch.branch_name) + " ")]);
  })], 2)]), _c('div', {
    staticClass: "col-md-6 offset-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": "branch_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("agent")))]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedAgentId,
      expression: "selectedAgentId"
    }],
    staticClass: "form-control",
    attrs: {
      "name": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.selectedAgentId = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.agent")) + " --")]), _vm._l(_vm.userAccesses, function (access, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": access.user_id
      }
    }, [_vm._v(" " + _vm._s(access.user.name) + " (" + _vm._s(access.user.ic_number) + ") ")]);
  })], 2)])]) : _vm._e(), _c('hr'), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "hibah_type_id"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("trust.type")) + " ")]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.hibahTypeString))])])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.pricing.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "price"
    }
  }, [_vm._v(_vm._s(_vm.$t("trust.hibah-price")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.pricing.$model,
      expression: "$v.pricing.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "disabled": _vm.hibah.payment_received_at
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.pricing, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.price")) + " --")]), _vm._l(_vm.pricings, function (pricing, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": pricing.id
      }
    }, [_vm._v(" RM " + _vm._s(pricing.price.toFixed(2)) + " ")]);
  })], 2), _vm.$v.pricing.$error && !_vm.$v.pricing.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" Harga Hibah tidak boleh dibiarkan kosong. ")]) : _vm._e()])])])])]) : _vm._e(), _c('div', {
    staticClass: "text-right mt-3"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.onSubmit
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save-next")) + " ")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }